import React from "react";
import Grid from "@material-ui/core/Grid";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { makeStyles } from "@material-ui/core";
import { Button } from "gatsby-material-ui-components";

const BackButton = ({ children, to }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="flex-end"
      className={classes.backButtonContainer}
    >
      <Button
        className={classes.backButton}
        endIcon={<ArrowForwardIosIcon />}
        to={to}
      >
        {children}
      </Button>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  backButtonContainer: {
    marginBottom: theme.spacing(4),
  },
  backButton: {
    fontWeight: 400,
  },
}));

export default BackButton;
