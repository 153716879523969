import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import React, { useState, useEffect, useContext } from "react";

import Container from "../components/Container";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import { makeStyles } from "@material-ui/core";

import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import { Link, Button } from "gatsby-material-ui-components";

import Layout from "../components/layout";
import Banner from "../components/Banner";
import FeaturedPostSlider from "../components/FeaturedPostSlider";
import PostList from "../components/PostList";
import BackButton from "../components/BackButton";
import PageDescription from "../components/PageDescription";
import MoreButton from "../components/MoreButton";

import useUrl from "../hooks/useUrl";
import useLanguage from "../hooks/useLanguage";
import useWpRestApi from "../hooks/useWpRestApi";

import i18nContext from "../i18n-context";

const PublicationDetailsPage = ({ data, pageContext }) => {
  const url = useUrl();
  const classes = useStyles();
  const muiTheme = useTheme();
  const { palette } = muiTheme;
  const lang = useLanguage();
  const i18n = useContext(i18nContext)[lang];
  const isDesktop = useMediaQuery(muiTheme.breakpoints.up("lg"));
  const [isFeaturedArticlesAvailable, setFeaturedArticlesAvailable] = useState(
    true
  );
  const [isLatestArticlesAvailable, setLatestArticlesAvailable] = useState(
    true
  );
  const [reportSeries, setReportSeries] = useState([]);

  const { publicationPage } = data;
  const { name, description, termTaxonomyId, translated } = pageContext;
  const trendingTopics =
    pageContext.research?.trendingTopics?.split(", ") || [];
  const sourceUrl =
    pageContext.reportSeriesGroup?.detailPageBanner?.sourceUrl || "";

  const featuredArticles = useWpRestApi(
    `/featured-posts?type=report&featured=1&cid=${termTaxonomyId}`,
    setFeaturedArticlesAvailable
  );
  const latestArticles = useWpRestApi(
    `/featured-posts?type=report&cid=${termTaxonomyId}`,
    setLatestArticlesAvailable
  );

  const urlPrefix = lang === "tc" ? "/zh-hant" : lang === "sc" ? "/zh-hans" : ""

  useEffect(() => {
    const fetchReportSeries = async () => {
      let langTag = "";
      if (window.location.pathname.includes("/zh-hant/"))
        langTag = "&language=zh-hant";
      if (window.location.pathname.includes("/zh-hans/"))
        langTag = "&language=zh-hans";
      await fetch(
        `${process.env.WP_REST_URL}/related-periodicals?rc_id=${termTaxonomyId}${langTag}`
      )
        .then((res) => res.json())
        .then((res) => setReportSeries(res))
        .catch((err) => console.log(err));
    };

    fetchReportSeries();
  }, []);

  return (
    <Layout pageTranslations={translated}>
      <Helmet>
        <title>{`${i18n.websiteName} - ${name}`}</title>

        {/* og tags for facebook and linkedin (except og:type and og:locale) */}
        <meta property="og:url" content={url} />
        <meta property="og:title" content={name} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={sourceUrl} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="800" />
        <meta
          property="og:locale"
          content={lang === "en" ? "en_US" : lang === "tc" ? "zh_TW" : "zh_CN"}
        />

        {/* og tags for twitter */}
        <meta name="twitter:card" content="summary" />
        {/* <meta name="twitter:site" content="@nytimes" />
        <meta name="twitter:creator" content="@SarahMaslinNir" /> */}
        <meta name="twitter:title" content={name} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={sourceUrl} />
      </Helmet>
      <Banner title={name} bannerUrl={sourceUrl} />
      <Container>
        {description && <PageDescription>{description}</PageDescription>}
        {isFeaturedArticlesAvailable && (
          <FeaturedPostSlider
            posts={featuredArticles}
            arrowPos={{
              buttonOffsetY: -213,
              leftButtonOffsetX: -38,
              rightButtonOffsetX: 38,
            }}
          />
        )}
        {isLatestArticlesAvailable && <PostList posts={latestArticles} />}

        {reportSeries &&
          reportSeries.some(
            (item) => Array.isArray(item.posts) && item.posts.length > 0
          ) && (
            <div className={classes.section}>
              <div className={classes.periodicalHeadingRoot}>
                <Typography
                  variant="h4"
                  className={classes.periodicalHeadingText}
                  align={isDesktop ? "left" : "center"}
                >
                  {i18n.sectionHeadings.periodicals}
                </Typography>
                <Divider className={classes.periodicalHeadingDivider} />
              </div>

              <Grid container spacing={3}>
                {reportSeries.map((node) => {
                  const { origin_id, banner, name, description, posts } = node;
                  return (
                    Array.isArray(posts) &&
                    posts.length > 0 && (
                      <Grid
                        key={`taxonomy-${termTaxonomyId}-report-series-${origin_id}`}
                        item
                        xs={12}
                        md={6}
                        container
                        direction="column"
                        className={classes.reportSeriesWrapper}
                      >
                        <img
                          src={banner}
                          alt=""
                          className={classes.reportSeriesBanner}
                        />
                        <Typography variant="body1" component="div" paragraph>
                          <strong>{name}</strong>
                        </Typography>
                        {description.length > 0 && (
                          <Typography variant="body2" align="justify" paragraph>
                            {description}
                          </Typography>
                        )}
                        <List>
                          {posts.slice(0, 5).map((post) => (
                            <Link to={post.uri} key={post.uri}>
                              <ListItem dense disableGutters>
                                <ListItemIcon>
                                  <ArrowRightIcon
                                    className={classes.arrowIcon}
                                  />
                                </ListItemIcon>
                                <ListItemText>
                                  <Typography variant="body2">
                                    {post.title}
                                  </Typography>
                                </ListItemText>
                              </ListItem>
                            </Link>
                          ))}
                        </List>
                        <Grid container justifyContent="center">
                          {posts.length > 5 && (
                            <MoreButton
                              color={palette.error.main}
                              textColor={palette.primary.main}
                              href={`${urlPrefix}/search?content_type=periodical&report_series=${encodeURIComponent(name)}`}
                            >
                              {i18n.showMore}
                            </MoreButton>
                          )}
                        </Grid>
                      </Grid>
                    )
                  );
                })}
              </Grid>
            </div>
          )}

        {Array.isArray(trendingTopics) && trendingTopics.length > 0 && (
          <div className={classes.section}>
            <div className={classes.periodicalHeadingRoot}>
              <Typography
                variant="h4"
                className={classes.periodicalHeadingText}
                align={isDesktop ? "left" : "center"}
              >
                {i18n.sectionHeadings.trendingTopics}
              </Typography>
              <Divider className={classes.periodicalHeadingDivider} />
            </div>
            <Grid container spacing={4}>
              {trendingTopics.map((topic) => (
                <Grid item key={`${name}-trending-topic-${topic}`}>
                  <Button to={`/search?search=${encodeURIComponent(topic)}`}>
                    <Typography>{topic}</Typography>
                  </Button>
                </Grid>
              ))}
            </Grid>
          </div>
        )}

        <BackButton to={publicationPage.uri}>
          {`${i18n.contentPage.backTo}${publicationPage.title}`}
        </BackButton>
      </Container>
    </Layout>
  );
};

const useStyles = makeStyles((theme) => ({
  description: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(10),
    fontFamily: "Open Sans",
    fontWeight: 400,
  },
  periodicalHeadingRoot: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("lg")]: {
      marginRight: theme.spacing(4),
    },
  },
  periodicalHeadingText: {
    fontFamily: "Open Sans",
  },
  periodicalHeadingDivider: {
    overflow: "hidden",
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.text.primary,
    [theme.breakpoints.down("xs")]: {
      marginLeft: `-${theme.spacing(2)}px`,
      marginRight: `-${theme.spacing(2)}px`,
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: `-${theme.spacing(4)}px`,
      marginRight: `-${theme.spacing(8)}px`,
    },
  },
  reportSeriesBanner: {
    marginBottom: theme.spacing(1),
    objectFit: "cover",
    width: "100%",
  },
  section: {
    marginBottom: theme.spacing(4),
  },
  // listItem: {
  //   backgroundColor: alpha(theme.palette.error.main, 0.3),
  // },
  arrowIcon: {
    color: theme.palette.error.main,
  },
  reportSeriesWrapper: {
    [theme.breakpoints.up("md")]: {
      "&:nth-of-type(odd)": {
        paddingRight: "24px !important",
      },
      "&:nth-of-type(even)": {
        paddingLeft: "24px !important",
      },
    },
  },
}));

export const query = graphql`
  query publicationDetailsPageQuery($id: String, $locale: String) {
    publicationPage: wpPage(
      pageType: { pageType: { eq: "2" } }
      locale: { locale: { eq: $locale } }
    ) {
      uri
      title
    }
    allWpReportSeries(
      filter: {
        catseriesrelationship: { parentTerm: { id: { eq: $id } } }
        locale: { locale: { eq: $locale } }
      }
    ) {
      nodes {
        id
        name
        link
        description
        reportSeriesGroup {
          bannerImage {
            sourceUrl
          }
        }
        periodicals {
          nodes {
            title
            id
            link
          }
        }
      }
    }
  }
`;

export default PublicationDetailsPage;
